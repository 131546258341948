import { useLayout } from '@/composables/useLayout';

const { setLayout } = useLayout();

export function addResizeListener() {
  window.addEventListener('resize', () => setLayout());
}
export const onErrorLoad = (e: any) => {
  e.target.src = '/img/no-image.png';
};

export const imgSrc = (dir: string, file: string) => {
  return `${dir}${file}`
}
