
export const setTheme = (name?: string, size?: string) => {
  if (name) {
    document.documentElement.dataset.theme = name;
    localStorage.setItem('theme', name);
  }
  if (size) {
    document.documentElement.dataset.size = size;
    localStorage.setItem('fontSize', size);
  }
}
export const getTheme = () => {
  const theme = {
    name: localStorage.getItem('theme') ?? 'light',
    size: localStorage.getItem('fontSize') ?? 'normal'
  }
  return theme
}
