import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Panel from "primevue/panel";
import YmapPlugin from 'vue-yandex-maps/dist/vue-yandex-maps.esm.js';
import Carousel from 'primevue/carousel';

const app = createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(YmapPlugin);

app.component('Button', Button);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Panel', Panel);
app.component('Carousel', Carousel);

app.mount('#app');

export default app;
