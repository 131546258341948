import { ref } from 'vue';

export interface Staff {
  id: number | string;
  sort?: number | string;
  isBoss?: boolean;
  fio: {
    first: string;
    last?: string;
    surname: string;
  };
  roles: string[];
  experience: string;
  photos: string[];
  certs?: string[];
  education?: string[];
}
export const staffList = ref<Staff[] | null>(null);

export async function getStaffList () {
  try {
    const staffListRequest = await fetch('/data/staff.json');
    staffList.value = await staffListRequest.json();
  }
  catch (e) {
    console.log('Ошибка загрузки данных')
  }
}

export const staffItem = (id: number | string) => {
  return staffList.value?.find(item => item.id === id);
};

export const staffItemImgSrc = (id: number | string) => {
  return staffItem(id)?.photos[0] || `/img/staff/${id}.jpg`;
};

export const rolesForPrint = (id: number | string) => {
  return staffItem(id)?.roles?.join(', ') || '';
};
