import { computed, ref } from 'vue';

export const services = ref({});
export const serviceList = computed(() => Object.entries(services.value));
export const priceList = ref(null);

export async function getServices () {
  try {
    const servicesRequest = await fetch('/data/services.json');
    services.value = await servicesRequest.json();
    await getPriceList();
  }
  catch (e) {
    console.log('Ошибка загрузки данных')
  }
}
export async function getPriceList () {
  try {
    const priceRequest = await fetch('/data/priceList.json');
    priceList.value = await priceRequest.json();
  }
  catch (e) {
    console.log('Ошибка загрузки данных')
  }
}
