import { computed, ref } from 'vue';

export interface Contacts {
  phone: string,
  messengers: string,
  email: string,
  address: string,
  city: string,
  metro:  string,
  schedule: string[],
}
export interface About {
  description: string[];
  mainPhoto: string;
  photos: string[];
  certificates: string[];
  links?: object[];
  contacts: Contacts;
  extraData?: string[]
}
export const aboutData = ref<About>();
export const contacts = computed(() => aboutData.value?.contacts as Contacts);
export async function getAboutData () {
  try {
    const dataRequest = await fetch('/data/about.json');
    aboutData.value = await dataRequest.json();
  }
  catch (e) {
    console.log(e, 'Ошибка загрузки данных')
  }
}

