import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/layout/Layout.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/pages/leading/Leading.vue'),
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/pages/about/About.vue'),
      },
      {
        path: '/services/:item?',
        name: 'Services',
        component: () => import('@/views/pages/services/Services.vue'),
      },
      {
        path: '/contacts',
        name: 'Contacts',
        component: () => import('@/views/pages/contacts/Contacts.vue'),
      },
      {
        path: '/staff/:id',
        name: 'Staff',
        component: () => import('@/views/pages/staff/Staff.vue'),
      },
    ],
  },
  /*  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/layout/error/404.vue'),
  },*/
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' }
}
});

export default router;
